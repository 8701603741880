import { Fragment } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { PRODUCT_TYPE } from '@/config/common';
import {
  formatDiscount,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';

const QuoteProductPriceLabel = ({ price }) => (
  <div className='text-sm md:text-lg lg-l:text-xl mt-4'>
    Price Starting from AED {parseFormatPriceValueFromAPI(price?.minPrice || 0)}
  </div>
);

const CartProductPriceLabel = ({ price, availableDiscount }) => (
  <div>
    <div className='flex font-400 items-center'>
      <div className='text-sm md:text-lg font-semibold'>
        AED {parseFormatPriceValueFromAPI(price?.priceToApply || 0)}
      </div>
      {Boolean(availableDiscount) && (
        <Fragment>
          <div className='line-through ms-3 text-dim-gray text-xs md:text-lg font-medium'>
            AED {parseFormatPriceValueFromAPI(price.price)}
          </div>
          <div className='ms-3 text-green text-xs md:text-lg font-medium'>
            {formatDiscount(availableDiscount.value)}% off
          </div>
        </Fragment>
      )}
    </div>
    <div className='text-xs text-brand mt-2 font-medium md:hidden'>
      Excluding VAT
    </div>
  </div>
);

const Label = ({ label }) => (
  <label className='text-base md:text-xl text-nero font-semibold'>
    {label}
  </label>
);

const PriceLabelForTicket = ({ type, displayText, price }) => (
  <div
    key={type}
    className='flex justify-between mb-4 last:mb-0'
  >
    <span className='w-4/6'>{displayText}</span>
    <span className='w-1/3 xxs:w-1/4'>AED {price}</span>
  </div>
);

// TODO: refactor code to current standards
const TicketProductPriceLabel = ({ showPricingDetails, ticketTypeDetails }) => {
  showPricingDetails && (
    <div className='mb-5 md:mb-8'>
      <Label {...{ label: 'Pricing Details' }} />
      <div className='mb-5 md:mb-6 mt-3 md:mt-4 text-xs md:text-sm font-medium md:max-w-105 rounded-md text-nero'>
        <div
          className='flex w-full items-center justify-between px-4 md:px-6 bg-brand-gradient rounded-t-md box-border
      font-medium text-left text-white h-10.5 border-l border-r border-cinnabar'
        >
          <div className='w-1/5'>
            <p> Guest </p>
          </div>
          <div className='w-3/5'>
            <p> Package Type </p>
          </div>
          <div className='w-1/5'>
            <p> Price </p>
          </div>
        </div>
        {ticketTypeDetails && ticketTypeDetails.adult.length > 0 && (
          <div className='flex w-full justify-between p-4 md:p-6 border border-t-0 border-cinnabar last:rounded-b-md'>
            <div className='w-1/5'>Adult</div>
            <div className='flex flex-col w-4/5'>
              {ticketTypeDetails.adult.map(
                ({ type, displayText, price }, index) => (
                  <PriceLabelForTicket
                    key={index}
                    {...{ type, displayText, price }}
                  />
                )
              )}
            </div>
          </div>
        )}
        {ticketTypeDetails && ticketTypeDetails.kids.length > 0 && (
          <div className='flex w-full justify-between p-4 md:p-6 border border-t-0 border-cinnabar last:rounded-b-md'>
            <div className='w-1/5'>Kids</div>
            <div className='flex flex-col w-4/5'>
              {ticketTypeDetails.kids.map(
                ({ type, displayText, price }, index) => (
                  <PriceLabelForTicket
                    key={index}
                    {...{ type, displayText, price }}
                  />
                )
              )}
            </div>
          </div>
        )}
        {ticketTypeDetails && ticketTypeDetails.couple.length > 0 && (
          <div className='flex w-full justify-between p-4 md:p-6 border border-t-0 border-cinnabar rounded-b-md'>
            <div className='w-1/5'>Couple</div>
            <div className='flex flex-col w-4/5'>
              {ticketTypeDetails.couple.map(
                ({ type, displayText, price }, index) => (
                  <PriceLabelForTicket
                    key={index}
                    {...{ type, displayText, price }}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ProductPrice = ({ product, showPricingDetails, ticketTypeDetails }) => {
  const productPriceLabelMap = {
    [PRODUCT_TYPE.QUOTE]: QuoteProductPriceLabel,
    [PRODUCT_TYPE.CART]: CartProductPriceLabel,
    [PRODUCT_TYPE.TICKET]: () => TicketProductPriceLabel
  };

  const PriceLabel = productPriceLabelMap[product.type];
  return (
    <PriceLabel {...{ ...product, showPricingDetails, ticketTypeDetails }} />
  );
};

export default ProductPrice;
